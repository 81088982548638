import slider from "./modules/slider";
import nav from "./modules/nav";
import showMore from "./modules/showMore";
import anchors from "./modules/achors";
import singleProduct from "./modules/singleProduct";

document.addEventListener('DOMContentLoaded', ()=> {
    slider();
    nav();
    showMore();
    anchors();
    singleProduct();
});