function slider () {
    const stockSlider = document.querySelector('.stocks-slider');

    if(stockSlider) {
        let slider = new Swiper(stockSlider , {
            slidesPerView: 'auto',
            breakpoints: {
                1: {
                    spaceBetween: 20,
                },
                768: {
                    spaceBetween: 25,
                },
                1241: {
                    spaceBetween: 30,
                }
            },
            freeMode: {
                enabled: true,
            },
            grabCursor: true,
            pagination: {
                el: ".slider__pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".slider__btn--next",
                prevEl: ".slider__btn--prev",
            },
        });
    }

    const reviewSlider = document.querySelector('.reviews__slider');

    if(reviewSlider) {
        let slider = new Swiper(reviewSlider , {
            breakpoints: {
                1: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    spaceBetween: 25,
                    slidesPerView: 2,
                },
                1241: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                }
            },
            grabCursor: true,
            pagination: {
                el: ".slider__pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".slider__btn--next",
                prevEl: ".slider__btn--prev",
            },
        });
    }
}

export default slider;