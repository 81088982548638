function showMore () {
    const showMoreBtn = document.querySelector('.catalog__show-more-btn');

    if(showMoreBtn) {
        const items = document.querySelectorAll('.catalog__item');

        for (let i = 0; i < 8 && i < items.length; i++) {
            items[i].classList.add('show');
        }
    
        showMoreBtn.addEventListener('click', function (e) {
            e.preventDefault();
    
            const visibleItems = document.querySelectorAll('.catalog__item.show');
    
            for (let i = visibleItems.length; i < visibleItems.length + 8 && i < items.length; i++) {
                items[i].classList.add('show');
            }
    
            if (document.querySelectorAll('.catalog__item:not(.show)').length === 0) {
                showMoreBtn.style.display = 'none';
            }
        });
    }
}

export default showMore;