function singleProduct () {
    const galleryImg = document.querySelectorAll('.single-product__gallery img');

    if(galleryImg.length > 0) {
        galleryImg.forEach(el=> {
            el.addEventListener('click', function() {
                let imgLink = this.getAttribute('src');
                const mainImg = document.querySelector('.single-product__photo-main');
    
                galleryImg.forEach(el=> el.classList.remove('active'));
                this.classList.add('active');
    
                mainImg.setAttribute('src', imgLink);
            });
        });
    }
}

export default singleProduct;